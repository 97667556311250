import React from 'react'

import '../../styles/layout/sidebar.css'

const Sidebar = () => {
  return (
    <div className="sidebar">
        <div className="sidebar__container">
        </div>
    </div>
  )
}

export default Sidebar